// Supported languages
export const languages = {
  en: 'English',
  es: 'Español',
  fr: 'Français'
} as const;

export type Lang = keyof typeof languages;
export const defaultLang: Lang = 'en';

// Utility to get language from URL
export function getLangFromUrl(url: URL): Lang {
  const [, lang] = url.pathname.split('/');
  if (lang in languages) return lang as Lang;
  return defaultLang;
}

// Utility to get URL for a different language
export function getUrlForLang(url: URL, lang: Lang): string {
  const [, , ...rest] = url.pathname.split('/');
  return `/${lang}/${rest.join('/')}`;
}

// Dictionary type for our translations
export type Translation = {
  nav: {
    home: string;
    products: string;
    about: string;
    sustainability: string;
    community: string;
    contact: string;
  };
  hero: {
    title: string;
    subtitle: string;
    cta: string;
  };
  footer: {
    description: string;
    company: {
      title: string;
      about: string;
      sustainability: string;
      community: string;
      contact: string;
    };
    products: {
      title: string;
      acai: string;
      maca: string;
      cacao: string;
      viewAll: string;
    };
    social: {
      instagram: string;
      facebook: string;
      linkedin: string;
    };
    copyright: string;
  };
  products: {
    title: string;
    viewDetails: string;
    subtitle: string;
    closeModal: string;
    categories: {
      ambient: string;
      frozen: string;
    };
    benefits: {
      title: string;
    };
    items: Array<{
      name: string;
      description: string;
      image: string;
      benefits: string[];
      category: 'ambient' | 'frozen';
    }>;
    carousel: {
      ourRange: string;
      findStockist: string;
      buyNow: string;
      viewDetailsButton: string;
      prevProduct: string;
      nextProduct: string;
    };
  };
  about: {
    title: string;
    description: string;
    sections: {
      story: {
        title: string;
        subtitle: string;
        description: string[];
        coreValues: string[];
        captions: {
          harvesting: string;
          sustainability: string;
          community: string;
          impact: string;
        };
      };
      sustainability: {
        title: string;
        subtitle: string;
        description: string[];
      };
      community: {
        title: string;
        subtitle: string;
        description: string[];
        programs: string[];
        economicImpact: {
          description: string;
          points: string[];
        };
      };
      values: {
        title: string;
        items: Array<{
          title: string;
          description: string;
        }>;
      };
      stats: Array<{
        label: string;
        value: string;
      }>;
    };
  };
  contact: {
    title: string;
    name: string;
    email: string;
    message: string;
    submit: string;
    sections: {
      partnerships: {
        name: string;
        description: string;
        email: string;
        phone: string;
      };
      location: {
        title: string;
        facility: string;
        address: {
          line1: string;
          line2: string;
          line3: string;
        };
      };
      social: {
        title: string;
        description: string;
        platforms: {
          instagram: string;
          facebook: string;
          linkedin: string;
        };
      };
    };
    toast: {
      emailCopied: string;
    };
  };
  faq: {
    title: string;
    items: Array<{
      question: string;
      answer: string;
    }>;
  };
};
