import type { Translation } from '../ui';

export const fr: Translation = {
  nav: {
    home: 'Accueil',
    products: 'Produits',
    about: 'Notre Histoire',
    sustainability: 'Notre Durabilité',
    community: 'Notre Communauté',
    contact: 'Contact'
  },
  hero: {
    title: 'Découvrez la Puissance des Superaliments Amazoniens',
    subtitle: 'Des ingrédients naturellement puissants, sourcés durablement pour votre bien-être',
    cta: 'Explorer les Produits'
  },
  footer: {
    description: 'Apporter la puissance des superaliments amazoniens au monde tout en préservant la biodiversité de la forêt et en soutenant les communautés indigènes.',
    company: {
      title: 'Entreprise',
      about: 'À Propos',
      sustainability: 'Durabilité',
      community: 'Impact Communautaire',
      contact: 'Contact'
    },
    products: {
      title: 'Produits',
      acai: 'Baie d\'Açaí',
      maca: 'Racine de Maca',
      cacao: 'Cacao Cru',
      viewAll: 'Voir Tout'
    },
    social: {
      instagram: 'Instagram',
      facebook: 'Facebook',
      linkedin: 'LinkedIn'
    },
    copyright: '© {year} Amazonia Vitality. Tous droits réservés.'
  },
  products: {
    title: 'Nos Superaliments',
    subtitle: 'Découvrez notre gamme soigneusement sélectionnée de superaliments premium, sourcés durablement de la forêt amazonienne.',
    viewDetails: 'Voir les Détails',
    closeModal: 'Fermer la fenêtre',
    categories: {
      ambient: 'Ambiant',
      frozen: 'Surgelé'
    },
    benefits: {
      title: 'Avantages Clés'
    },
    carousel: {
      ourRange: 'NOTRE GAMME',
      findStockist: 'TROUVER UN REVENDEUR',
      buyNow: 'ACHETER',
      viewDetailsButton: 'Voir les Détails',
      prevProduct: 'Produit Précédent',
      nextProduct: 'Produit Suivant'
    },
    items: [
      {
        name: 'Baie d\'Açaí',
        description: 'Riche en antioxydants et en graisses saines pour le cœur, notre poudre d\'Açaí pure offre le goût authentique de l\'Amazonie.',
        image: 'https://images.pexels.com/photos/1153655/pexels-photo-1153655.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Riche en antioxydants',
          'Soutient la santé cardiaque',
          'Augmente les niveaux d\'énergie',
          'Renforce le système immunitaire'
        ],
        category: 'ambient'
      },
      {
        name: 'Racine de Maca',
        description: 'Superaliment ancestral péruvien connu pour stimuler l\'énergie, l\'endurance et l\'équilibre hormonal.',
        image: 'https://images.pexels.com/photos/4110250/pexels-photo-4110250.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Stimulant naturel d\'énergie',
          'Soutien de l\'équilibre hormonal',
          'Améliore l\'endurance',
          'Riche en vitamines et minéraux'
        ],
        category: 'ambient'
      },
      {
        name: 'Cacao Cru',
        description: 'Cacao cru biologique riche en magnésium, fer et composés améliorant l\'humeur.',
        image: 'https://images.pexels.com/photos/867470/pexels-photo-867470.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Riche en magnésium',
          'Riche en antioxydants',
          'Améliore l\'humeur',
          'Boost d\'énergie naturel'
        ],
        category: 'ambient'
      },
      {
        name: 'Graine de Guarana',
        description: 'Stimulant naturel d\'énergie aux propriétés cognitives améliorées de la forêt amazonienne.',
        image: 'https://images.pexels.com/photos/2067396/pexels-photo-2067396.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Source naturelle de caféine',
          'Améliore la concentration',
          'Stimule le métabolisme',
          'Améliore la clarté mentale'
        ],
        category: 'frozen'
      },
      {
        name: 'Noix du Brésil',
        description: 'Noix riches en sélénium qui soutiennent la fonction thyroïdienne et la santé du système immunitaire.',
        image: 'https://images.pexels.com/photos/4033296/pexels-photo-4033296.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Riche en sélénium',
          'Soutient la santé thyroïdienne',
          'Graisses saines pour le cœur',
          'Collation riche en protéines'
        ],
        category: 'frozen'
      },
      {
        name: 'Sacha Inchi',
        description: 'Superaliment ancestral inca riche en oméga-3, protéines et vitamine E.',
        image: 'https://images.pexels.com/photos/1907642/pexels-photo-1907642.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Riche en oméga-3',
          'Source complète de protéines',
          'Riche en vitamine E',
          'Soutient la santé articulaire'
        ],
        category: 'frozen'
      }
    ]
  },
  about: {
    title: 'À Propos de Nous',
    description: 'Nous nous engageons à vous apporter les meilleurs superaliments de la forêt amazonienne tout en soutenant les communautés locales et les pratiques durables.',
    sections: {
      story: {
        title: 'Notre Histoire',
        subtitle: 'Nos Valeurs Fondamentales',
        description: [
          'Au cœur de la forêt amazonienne, nous travaillons directement avec les communautés indigènes pour récolter durablement les superaliments les plus puissants de la nature.',
          'Depuis plus de deux décennies, nous avons construit des relations solides avec les communautés locales, assurant des pratiques de commerce équitable et des méthodes de récolte durables.'
        ],
        coreValues: [
          'Pratiques de récolte durables qui protègent la biodiversité amazonienne',
          'Partenariats de commerce équitable avec les communautés indigènes',
          'Préservation des connaissances traditionnelles et du patrimoine culturel',
          'Investissement dans l\'infrastructure locale et l\'éducation'
        ],
        captions: {
          harvesting: 'Préserver la sagesse ancestrale de la récolte',
          sustainability: 'Durabilité en action',
          community: 'Autonomiser les communautés locales',
          impact: 'Notre Impact'
        }
      },
      sustainability: {
        title: 'Notre Durabilité',
        subtitle: 'Nos Initiatives',
        description: [
          'Notre engagement envers la durabilité va au-delà des mots. Nous mettons en œuvre des pratiques qui protègent et préservent le délicat écosystème amazonien.',
          'À travers des méthodes de récolte traditionnelles et la technologie moderne, nous nous assurons que chaque produit respecte l\'équilibre naturel de la forêt.'
        ]
      },
      community: {
        title: 'Notre Impact Communautaire',
        subtitle: 'Nos Programmes',
        description: [
          'Notre engagement envers les communautés indigènes va au-delà des partenariats commerciaux. Nous avons développé une approche globale du développement communautaire.',
          'Nous croyons que la véritable durabilité ne peut être atteinte que lorsque les communautés locales prospèrent.'
        ],
        programs: [
          'Initiatives éducatives pour les enfants et les jeunes adultes',
          'Programmes d\'amélioration de l\'accès aux soins de santé',
          'Projets de développement d\'infrastructure',
          'Initiatives d\'autonomisation des femmes',
          'Développement du leadership des jeunes',
          'Programmes de préservation culturelle',
          'Formation à l\'agriculture durable',
          'Soutien à la certification du commerce équitable'
        ],
        economicImpact: {
          description: 'Grâce à nos pratiques de commerce équitable et à nos initiatives de développement communautaire, nous avons aidé à créer des sources de revenus durables pour des centaines de familles.',
          points: [
            'Création d\'installations de transformation locales',
            'Développement de nouvelles opportunités de marché',
            'Investissement dans les entreprises communautaires',
            'Formation et développement des compétences',
            'Accès aux marchés mondiaux et prix équitables'
          ]
        }
      },
      values: {
        title: 'Nos Engagements',
        items: [
          {
            title: 'Durabilité',
            description: 'Nous pratiquons des méthodes de récolte durables pour préserver la forêt amazonienne pour les générations futures.'
          },
          {
            title: 'Impact Communautaire',
            description: 'Nous travaillons directement avec les communautés indigènes, assurant le commerce équitable et soutenant les économies locales.'
          },
          {
            title: 'Assurance Qualité',
            description: 'Chaque produit subit des tests rigoureux pour garantir la plus haute qualité et valeur nutritionnelle.'
          }
        ]
      },
      stats: [
        {
          label: 'Communautés Indigènes',
          value: '15+'
        },
        {
          label: 'Hectares Protégés',
          value: '5000+'
        },
        {
          label: 'Produits Durables',
          value: '20+'
        },
        {
          label: 'Années d\'Héritage',
          value: '25+'
        }
      ]
    }
  },
  contact: {
    title: 'Contactez-Nous',
    name: 'Nom',
    email: 'E-mail',
    message: 'Message',
    submit: 'Envoyer le Message',
    sections: {
      partnerships: {
        name: 'Partenariats Durables',
        description: 'Rejoignez notre mission de protection de la forêt amazonienne et de soutien aux communautés locales',
        email: 'sustainability@amazoniavitality.com',
        phone: '+1 (555) 987-6543'
      },
      location: {
        title: 'Visitez Nos Installations',
        facility: 'Centre de Traitement Amazonien',
        address: {
          line1: 'Parc d\'Entreprises Durables',
          line2: 'Manaus',
          line3: 'Brésil'
        }
      },
      social: {
        title: 'Suivez-Nous',
        description: 'Restez informé de nos dernières initiatives.',
        platforms: {
          instagram: 'Instagram',
          facebook: 'Facebook',
          linkedin: 'LinkedIn'
        }
      }
    },
    toast: {
      emailCopied: 'E-mail copié dans le presse-papiers'
    }
  },
  faq: {
    title: 'FAQ',
    items: [
      {
        question: 'Quand ma commande arrivera-t-elle ?',
        answer: 'Le délai de livraison est fixé par nos partenaires de livraison, selon la méthode choisie. Plus de détails sont disponibles dans la confirmation de commande.'
      },
      {
        question: 'Comment puis-je suivre ma commande ?',
        answer: 'Vous pouvez suivre votre commande en vous connectant à votre compte et en consultant son statut. Nous envoyons également les informations de suivi par e-mail une fois la commande expédiée.'
      },
      {
        question: 'Quelle est votre politique de retour ?',
        answer: 'Nous offrons une politique de retour de 30 jours pour la plupart des articles. Les produits doivent être inutilisés et dans leur emballage d\'origine. Contactez notre service client pour initier un retour.'
      },
      {
        question: 'Comment puis-je modifier une commande existante ?',
        answer: 'Pour modifier une commande existante, veuillez contacter notre service client dès que possible. Les modifications ne peuvent être effectuées qu\'avant l\'expédition de la commande.'
      }
    ]
  }
};
