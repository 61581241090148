import type { Translation } from '../ui';

export const en: Translation = {
  nav: {
    home: 'Home',
    products: 'Products',
    about: 'Our Story',
    sustainability: 'Our Sustainability',
    community: 'Our Community',
    contact: 'Contact'
  },
  hero: {
    title: 'Discover the Power of Amazonian Superfoods',
    subtitle: 'Sustainably sourced, naturally powerful ingredients for your wellbeing',
    cta: 'Explore Products'
  },
  footer: {
    description: 'Bringing the power of Amazonian superfoods to the world while preserving the rainforest\'s biodiversity and supporting indigenous communities.',
    company: {
      title: 'Company',
      about: 'About Us',
      sustainability: 'Sustainability',
      community: 'Community Impact',
      contact: 'Contact'
    },
    products: {
      title: 'Products',
      acai: 'Açaí Berry',
      maca: 'Maca Root',
      cacao: 'Raw Cacao',
      viewAll: 'View All'
    },
    social: {
      instagram: 'Instagram',
      facebook: 'Facebook',
      linkedin: 'LinkedIn'
    },
    copyright: '© {year} Amazonia Vitality. All rights reserved.'
  },
  products: {
    title: 'Our Superfoods',
    subtitle: 'Discover our carefully selected range of premium superfoods, sustainably sourced from the Amazon rainforest.',
    viewDetails: 'View Details',
    closeModal: 'Close modal',
    categories: {
      ambient: 'Ambient',
      frozen: 'Frozen'
    },
    benefits: {
      title: 'Key Benefits'
    },
    carousel: {
      ourRange: 'OUR RANGE',
      findStockist: 'FIND A STOCKIST',
      buyNow: 'BUY NOW',
      viewDetailsButton: 'View Details',
      prevProduct: 'Previous Product',
      nextProduct: 'Next Product'
    },
    items: [
      {
        name: 'Açaí Berry',
        description: 'Rich in antioxidants and heart-healthy fats, our pure Açaí powder delivers the authentic taste of the Amazon.',
        image: 'https://images.pexels.com/photos/1153655/pexels-photo-1153655.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'High in antioxidants',
          'Supports heart health',
          'Boosts energy levels',
          'Enhances immune system'
        ],
        category: 'ambient'
      },
      {
        name: 'Maca Root',
        description: 'Ancient Peruvian superfood known for boosting energy, endurance, and hormonal balance.',
        image: 'https://images.pexels.com/photos/4110250/pexels-photo-4110250.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Natural energy booster',
          'Hormonal balance support',
          'Improves endurance',
          'Rich in vitamins & minerals'
        ],
        category: 'ambient'
      },
      {
        name: 'Raw Cacao',
        description: 'Organic raw cacao packed with magnesium, iron, and mood-enhancing compounds.',
        image: 'https://images.pexels.com/photos/867470/pexels-photo-867470.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'High in magnesium',
          'Rich in antioxidants',
          'Mood enhancer',
          'Natural energy boost'
        ],
        category: 'ambient'
      },
      {
        name: 'Guarana Seed',
        description: 'Natural energy booster with cognitive-enhancing properties from the Amazon rainforest.',
        image: 'https://images.pexels.com/photos/2067396/pexels-photo-2067396.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Natural caffeine source',
          'Improves focus',
          'Boosts metabolism',
          'Enhances mental clarity'
        ],
        category: 'frozen'
      },
      {
        name: 'Brazil Nuts',
        description: 'Selenium-rich nuts that support thyroid function and immune system health.',
        image: 'https://images.pexels.com/photos/4033296/pexels-photo-4033296.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'High in selenium',
          'Supports thyroid health',
          'Heart-healthy fats',
          'Protein-rich snack'
        ],
        category: 'frozen'
      },
      {
        name: 'Sacha Inchi',
        description: 'Ancient Incan superfood rich in omega-3s, protein, and vitamin E.',
        image: 'https://images.pexels.com/photos/1907642/pexels-photo-1907642.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Rich in omega-3',
          'Complete protein source',
          'High in vitamin E',
          'Supports joint health'
        ],
        category: 'frozen'
      }
    ]
  },
  about: {
    title: 'About Us',
    description: 'We are committed to bringing you the finest superfoods from the Amazon rainforest while supporting local communities and sustainable practices.',
    sections: {
      story: {
        title: 'Our Story',
        subtitle: 'Our Core Values',
        description: [
          'Deep in the heart of the Amazon rainforest, we work directly with indigenous communities to sustainably harvest nature\'s most potent superfoods.',
          'For over two decades, we\'ve built strong relationships with local communities, ensuring fair trade practices and sustainable harvesting methods.'
        ],
        coreValues: [
          'Sustainable harvesting practices that protect the Amazon\'s biodiversity',
          'Fair trade partnerships with indigenous communities',
          'Preservation of traditional knowledge and cultural heritage',
          'Investment in local infrastructure and education'
        ],
        captions: {
          harvesting: 'Preserving ancestral harvesting wisdom',
          sustainability: 'Sustainability in action',
          community: 'Empowering local communities',
          impact: 'Our Impact'
        }
      },
      sustainability: {
        title: 'Our Sustainability',
        subtitle: 'Our Initiatives',
        description: [
          'Our commitment to sustainability goes beyond words. We implement practices that protect and preserve the Amazon\'s delicate ecosystem.',
          'Through traditional harvesting methods and modern technology, we ensure every product respects the forest\'s natural balance.'
        ]
      },
      community: {
        title: 'Our Community Impact',
        subtitle: 'Our Programs',
        description: [
          'Our commitment to indigenous communities goes far beyond business partnerships. We\'ve developed a comprehensive approach to community development.',
          'We believe that true sustainability can only be achieved when local communities thrive.'
        ],
        programs: [
          'Educational initiatives for children and young adults',
          'Healthcare access improvement programs',
          'Infrastructure development projects',
          'Women\'s empowerment initiatives',
          'Youth leadership development',
          'Cultural preservation programs',
          'Sustainable agriculture training',
          'Fair trade certification support'
        ],
        economicImpact: {
          description: 'Through our fair trade practices and community development initiatives, we\'ve helped create sustainable income sources for hundreds of families.',
          points: [
            'Creation of local processing facilities',
            'Development of new market opportunities',
            'Investment in community-owned enterprises',
            'Skills training and capacity building',
            'Access to global markets and fair prices'
          ]
        }
      },
      values: {
        title: 'Our Commitments',
        items: [
          {
            title: 'Sustainability',
            description: 'We practice sustainable harvesting methods to preserve the Amazon rainforest for future generations.'
          },
          {
            title: 'Community Impact',
            description: 'We work directly with indigenous communities, ensuring fair trade and supporting local economies.'
          },
          {
            title: 'Quality Assurance',
            description: 'Every product undergoes rigorous testing to ensure the highest quality and nutritional value.'
          }
        ]
      },
      stats: [
        {
          label: 'Indigenous Communities',
          value: '15+'
        },
        {
          label: 'Hectares Protected',
          value: '5000+'
        },
        {
          label: 'Sustainable Products',
          value: '20+'
        },
        {
          label: 'Years of Heritage',
          value: '25+'
        }
      ]
    }
  },
  contact: {
    title: 'Connect With Us',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    submit: 'Send Message',
    sections: {
      partnerships: {
        name: 'Sustainability Partnerships',
        description: 'Join our mission to protect the Amazon rainforest and support local communities',
        email: 'sustainability@amazoniavitality.com',
        phone: '+1 (555) 987-6543'
      },
      location: {
        title: 'Visit Our Facility',
        facility: 'Amazon Processing Center',
        address: {
          line1: 'Sustainable Business Park',
          line2: 'Manaus',
          line3: 'Brazil'
        }
      },
      social: {
        title: 'Follow Us',
        description: 'Stay updated with our latest initiatives.',
        platforms: {
          instagram: 'Instagram',
          facebook: 'Facebook',
          linkedin: 'LinkedIn'
        }
      }
    },
    toast: {
      emailCopied: 'Email copied to clipboard'
    }
  },
  faq: {
    title: 'FAQ',
    items: [
      {
        question: 'When will my order arrive?',
        answer: 'Shipping time is set by our delivery partners, according to the delivery method chosen by you. Additional details can be found in the order confirmation.'
      },
      {
        question: 'How do I track my order?',
        answer: 'You can track your order by logging into your account and viewing the order status. We also send tracking information via email once your order ships.'
      },
      {
        question: 'What\'s your return policy?',
        answer: 'We offer a 30-day return policy for most items. Products must be unused and in their original packaging. Contact our customer service team to initiate a return.'
      },
      {
        question: 'How do I make changes to an existing order?',
        answer: 'To modify an existing order, please contact our customer service team as soon as possible. Changes can only be made before the order ships.'
      }
    ]
  }
};
