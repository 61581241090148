import type { Translation } from '../ui';

export const es: Translation = {
  nav: {
    home: 'Inicio',
    products: 'Productos',
    about: 'Nuestra Historia',
    sustainability: 'Nuestra Sostenibilidad',
    community: 'Nuestra Comunidad',
    contact: 'Contacto'
  },
  hero: {
    title: 'Descubre el Poder de los Superalimentos Amazónicos',
    subtitle: 'Ingredientes naturalmente poderosos, obtenidos de manera sostenible para tu bienestar',
    cta: 'Explorar Productos'
  },
  footer: {
    description: 'Llevando el poder de los superalimentos amazónicos al mundo mientras preservamos la biodiversidad de la selva y apoyamos a las comunidades indígenas.',
    company: {
      title: 'Empresa',
      about: 'Sobre Nosotros',
      sustainability: 'Sostenibilidad',
      community: 'Impacto Comunitario',
      contact: 'Contacto'
    },
    products: {
      title: 'Productos',
      acai: 'Açaí Berry',
      maca: 'Raíz de Maca',
      cacao: 'Cacao Crudo',
      viewAll: 'Ver Todos'
    },
    social: {
      instagram: 'Instagram',
      facebook: 'Facebook',
      linkedin: 'LinkedIn'
    },
    copyright: '© {year} Amazonia Vitality. Todos los derechos reservados.'
  },
  products: {
    title: 'Nuestros Superalimentos',
    subtitle: 'Descubre nuestra cuidadosa selección de superalimentos premium, obtenidos de manera sostenible de la selva amazónica.',
    viewDetails: 'Ver Detalles',
    closeModal: 'Cerrar modal',
    categories: {
      ambient: 'Ambiente',
      frozen: 'Congelado'
    },
    benefits: {
      title: 'Beneficios Clave'
    },
    carousel: {
      ourRange: 'NUESTRA GAMA',
      findStockist: 'ENCUENTRA UN DISTRIBUIDOR',
      buyNow: 'COMPRAR AHORA',
      viewDetailsButton: 'Ver Detalles',
      prevProduct: 'Producto Anterior',
      nextProduct: 'Siguiente Producto'
    },
    items: [
      {
        name: 'Açaí Berry',
        description: 'Rico en antioxidantes y grasas saludables para el corazón, nuestro polvo de Açaí puro ofrece el auténtico sabor del Amazonas.',
        image: 'https://images.pexels.com/photos/1153655/pexels-photo-1153655.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Alto en antioxidantes',
          'Apoya la salud del corazón',
          'Aumenta los niveles de energía',
          'Mejora el sistema inmunológico'
        ],
        category: 'ambient'
      },
      {
        name: 'Raíz de Maca',
        description: 'Superalimento ancestral peruano conocido por aumentar la energía, la resistencia y el equilibrio hormonal.',
        image: 'https://images.pexels.com/photos/4110250/pexels-photo-4110250.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Potenciador natural de energía',
          'Apoyo al equilibrio hormonal',
          'Mejora la resistencia',
          'Rico en vitaminas y minerales'
        ],
        category: 'ambient'
      },
      {
        name: 'Cacao Crudo',
        description: 'Cacao crudo orgánico rico en magnesio, hierro y compuestos que mejoran el estado de ánimo.',
        image: 'https://images.pexels.com/photos/867470/pexels-photo-867470.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Alto en magnesio',
          'Rico en antioxidantes',
          'Mejora el estado de ánimo',
          'Impulso natural de energía'
        ],
        category: 'ambient'
      },
      {
        name: 'Semilla de Guaraná',
        description: 'Potenciador natural de energía con propiedades cognitivas mejoradas de la selva amazónica.',
        image: 'https://images.pexels.com/photos/2067396/pexels-photo-2067396.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Fuente natural de cafeína',
          'Mejora la concentración',
          'Aumenta el metabolismo',
          'Mejora la claridad mental'
        ],
        category: 'frozen'
      },
      {
        name: 'Nueces de Brasil',
        description: 'Nueces ricas en selenio que apoyan la función tiroidea y la salud del sistema inmunológico.',
        image: 'https://images.pexels.com/photos/4033296/pexels-photo-4033296.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Alto en selenio',
          'Apoya la salud tiroidea',
          'Grasas saludables para el corazón',
          'Snack rico en proteínas'
        ],
        category: 'frozen'
      },
      {
        name: 'Sacha Inchi',
        description: 'Superalimento ancestral inca rico en omega-3, proteínas y vitamina E.',
        image: 'https://images.pexels.com/photos/1907642/pexels-photo-1907642.jpeg?auto=compress&cs=tinysrgb&w=800',
        benefits: [
          'Rico en omega-3',
          'Fuente completa de proteínas',
          'Alto en vitamina E',
          'Apoya la salud articular'
        ],
        category: 'frozen'
      }
    ]
  },
  about: {
    title: 'Sobre Nosotros',
    description: 'Estamos comprometidos a traerte los mejores superalimentos de la selva amazónica mientras apoyamos a las comunidades locales y las prácticas sostenibles.',
    sections: {
      story: {
        title: 'Nuestra Historia',
        subtitle: 'Nuestros Valores Fundamentales',
        description: [
          'En el corazón de la selva amazónica, trabajamos directamente con comunidades indígenas para cosechar de manera sostenible los superalimentos más potentes de la naturaleza.',
          'Durante más de dos décadas, hemos construido relaciones sólidas con las comunidades locales, asegurando prácticas de comercio justo y métodos de cosecha sostenibles.'
        ],
        coreValues: [
          'Prácticas de cosecha sostenible que protegen la biodiversidad del Amazonas',
          'Asociaciones de comercio justo con comunidades indígenas',
          'Preservación del conocimiento tradicional y patrimonio cultural',
          'Inversión en infraestructura local y educación'
        ],
        captions: {
          harvesting: 'Preservando la sabiduría ancestral de la cosecha',
          sustainability: 'Sostenibilidad en acción',
          community: 'Empoderando a las comunidades locales',
          impact: 'Nuestro Impacto'
        }
      },
      sustainability: {
        title: 'Nuestra Sostenibilidad',
        subtitle: 'Nuestras Iniciativas',
        description: [
          'Nuestro compromiso con la sostenibilidad va más allá de las palabras. Implementamos prácticas que protegen y preservan el delicado ecosistema amazónico.',
          'A través de métodos de cosecha tradicionales y tecnología moderna, aseguramos que cada producto respete el equilibrio natural de la selva.'
        ]
      },
      community: {
        title: 'Nuestro Impacto Comunitario',
        subtitle: 'Nuestros Programas',
        description: [
          'Nuestro compromiso con las comunidades indígenas va más allá de las asociaciones comerciales. Hemos desarrollado un enfoque integral para el desarrollo comunitario.',
          'Creemos que la verdadera sostenibilidad solo se puede lograr cuando las comunidades locales prosperan.'
        ],
        programs: [
          'Iniciativas educativas para niños y jóvenes adultos',
          'Programas de mejora del acceso a la salud',
          'Proyectos de desarrollo de infraestructura',
          'Iniciativas de empoderamiento de la mujer',
          'Desarrollo de liderazgo juvenil',
          'Programas de preservación cultural',
          'Capacitación en agricultura sostenible',
          'Apoyo para la certificación de comercio justo'
        ],
        economicImpact: {
          description: 'A través de nuestras prácticas de comercio justo e iniciativas de desarrollo comunitario, hemos ayudado a crear fuentes de ingresos sostenibles para cientos de familias.',
          points: [
            'Creación de instalaciones de procesamiento locales',
            'Desarrollo de nuevas oportunidades de mercado',
            'Inversión en empresas propiedad de la comunidad',
            'Capacitación y desarrollo de habilidades',
            'Acceso a mercados globales y precios justos'
          ]
        }
      },
      values: {
        title: 'Nuestros Compromisos',
        items: [
          {
            title: 'Sostenibilidad',
            description: 'Practicamos métodos de cosecha sostenible para preservar la selva amazónica para las generaciones futuras.'
          },
          {
            title: 'Impacto Comunitario',
            description: 'Trabajamos directamente con comunidades indígenas, asegurando comercio justo y apoyando economías locales.'
          },
          {
            title: 'Garantía de Calidad',
            description: 'Cada producto se somete a pruebas rigurosas para garantizar la más alta calidad y valor nutricional.'
          }
        ]
      },
      stats: [
        {
          label: 'Comunidades Indígenas',
          value: '15+'
        },
        {
          label: 'Hectáreas Protegidas',
          value: '5000+'
        },
        {
          label: 'Productos Sostenibles',
          value: '20+'
        },
        {
          label: 'Años de Herencia',
          value: '25+'
        }
      ]
    }
  },
  contact: {
    title: 'Contáctanos',
    name: 'Nombre',
    email: 'Correo Electrónico',
    message: 'Mensaje',
    submit: 'Enviar Mensaje',
    sections: {
      partnerships: {
        name: 'Alianzas Sostenibles',
        description: 'Únete a nuestra misión de proteger la selva amazónica y apoyar a las comunidades locales',
        email: 'sustainability@amazoniavitality.com',
        phone: '+1 (555) 987-6543'
      },
      location: {
        title: 'Visita Nuestras Instalaciones',
        facility: 'Centro de Procesamiento Amazónico',
        address: {
          line1: 'Parque Empresarial Sostenible',
          line2: 'Manaus',
          line3: 'Brasil'
        }
      },
      social: {
        title: 'Síguenos',
        description: 'Mantente actualizado con nuestras últimas iniciativas.',
        platforms: {
          instagram: 'Instagram',
          facebook: 'Facebook',
          linkedin: 'LinkedIn'
        }
      }
    },
    toast: {
      emailCopied: 'Correo electrónico copiado al portapapeles'
    }
  },
  faq: {
    title: 'Preguntas Frecuentes',
    items: [
      {
        question: '¿Cuándo llegará mi pedido?',
        answer: 'El tiempo de envío lo establecen nuestros socios de entrega, según el método de envío que elijas. Encontrarás más detalles en la confirmación del pedido.'
      },
      {
        question: '¿Cómo puedo rastrear mi pedido?',
        answer: 'Puedes rastrear tu pedido iniciando sesión en tu cuenta y viendo el estado del pedido. También enviamos información de seguimiento por correo electrónico una vez que se envía tu pedido.'
      },
      {
        question: '¿Cuál es su política de devoluciones?',
        answer: 'Ofrecemos una política de devolución de 30 días para la mayoría de los artículos. Los productos deben estar sin usar y en su empaque original. Contacta a nuestro equipo de servicio al cliente para iniciar una devolución.'
      },
      {
        question: '¿Cómo puedo hacer cambios en un pedido existente?',
        answer: 'Para modificar un pedido existente, contacta a nuestro equipo de servicio al cliente lo antes posible. Los cambios solo se pueden realizar antes de que se envíe el pedido.'
      }
    ]
  }
};
