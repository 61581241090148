import type { Translation, Lang } from './ui';
import { en } from './translations/en';
import { es } from './translations/es';
import { fr } from './translations/fr';

const translations = {
  en,
  es,
  fr
} as const;

export function getTranslations(lang: Lang): Translation {
  if (lang in translations) {
    return translations[lang as keyof typeof translations];
  }
  return translations.en;
}

export { translations };
export type { Translation };
